<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                非凸科技受邀出席国元证券“北京私募会”，探讨行业发展与合作机会
              </p>
              <div class="fst-italic mb-2">2023年7月20日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >国元证券</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/48/01-北京私募会.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    近日，由国元证券北京西坝河南路营业部主办的“北京私募会”2023年第五期交流活动在北京顺利举行，非凸科技受邀出席，与林亿资本、龙隐投资、中量财富、鑫顺和康等10多家私募机构，共同探讨私募行业发展与合作机会。
                  </p>
                  <p>
                    国元证券立足金融服务实体经济本源，聚焦提升综合金融服务能力，为投资者提供集PB交易、融资融券、私募代销、资产托管、种子基金跟投、衍生品投资、研究咨询于一体的全流程、一站式私募综合金融服务。未来，国元证券将继续深化金融科技创新，持续提升自身专业能力和服务水准，践行金融服务实体经济使命，为构建新发展格局、助力证券行业和实体经济高质量发展作出更大贡献。国元证券也将继续推进系列活动的开展，为私募机构提供更丰富的行业分享、更广阔的交流平台以及更优质、专业、全面的机构服务。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/48/02-朱为.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    会上，非凸科技北京分公司合伙人朱为从公司竞争力、算法表现及机构服务等角度进行了分享。近年来，科技创新被提到前所未有的高度，数智交易已然成为赋能机构投资业务发展的新趋势。非凸科技高度重视科技创新，积极探索交易场景应用，希望以核心技术优势引领行业对交易执行的更高要求。在开发交易新策略、突破算力新瓶颈、构建新算法的征程中，非凸科技始终将客户真实需求放在首位，追求系统的极致稳定和算法的持续高效，其算法总线结合了接入、算法、回测、仿真、评价、监控等模块，提供一站式算法业务解决方案，从而助力机构客户获得金融科技发展带来的交易红利。
                  </p>
                  <p>
                    在问答环节，朱为就底仓增强算法、Rust全栈开发、交易场景等问题进行了解答。在谈及非凸底仓增强算法能否帮助降低产品收益波动率时，他表示，“非凸底仓增强算法基于客户持有的股票持仓，利用机器学习等前沿技术，根据股票横截面和时序数据做短周期预测，全自动操作，抓取行情波动价差，能够为产品带来较为稳定的收益增厚，以此降低产品收益波动率及优化夏普。”
                  </p>
                  <p>
                    此次交流活动，也为非凸科技与国元证券的深入合作提供了契机，期待未来双方能够达成总对总合作，发挥各自资源、技术、服务等优势，持续创新，共同推动数智交易生态建设。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News46",
};
</script>

<style></style>
